
import { Component } from 'vue-property-decorator';
import { LoggedInComponentBase } from '@/components/base/loggedInComponentBase';
import { Route } from 'vue-router';
import { HelpAndFAQsModule } from '@/store';
import { getModule } from 'vuex-module-decorators';
import { Article } from '@/api/contracts/helpAndFAQs/article';
import { SidebarMixin } from '@/mixins/sidebarMixin';
// https://github.com/kaorun343/vue-youtube-embed
import Vue from 'vue';
import VueYouTubeEmbed, { YouTubePlayer } from 'vue-youtube-embed';

Vue.use(VueYouTubeEmbed, { global: false });

const helpAndFAQsModule = getModule(HelpAndFAQsModule);

@Component({
  components: {
    youtube: YouTubePlayer,
  },
  mixins: [SidebarMixin],
})
export default class ArticleDetail extends LoggedInComponentBase {
  // eslint-disable-next-line
  private player: any;

  public get article(): Article | null {
    return helpAndFAQsModule.currentHelpArticle;
  }

  public get imageHeight(): number {
    return this.$vuetify.breakpoint.smAndDown ? 300 : 500;
  }

  public async beforeRouteEnter(
    to: Route,
    from: Route,
    next: (arg?: boolean | ((vm: ArticleDetail) => void)) => void
  ): Promise<void> {
    try {
      await helpAndFAQsModule.getArticleById(to.params.id);
      next();
    } catch (err) {
      console.error(err.message);
      next(false);
    }
  }

  // eslint-disable-next-line
  ready(event: any) {
    this.player = event.target;
  }
}
